import { Tabs, TabList, TabPanels, Tab, TabPanel, Icon, Box } from "@chakra-ui/react";
import { SimpleTabsParameters } from "../../api/types";
import { BsStars } from "react-icons/bs";

import "./SimpleTabs.css";

const loading = (
  <Box className="fading" mt={1}>
    <Icon as={BsStars} color="orange"/> &nbsp;
  </Box>
);

const SimpleTabs = ({
  tab1,
  tab2,
  tab3,
  tab1Loading,
  tab2Loading,
  tab3Loading,
  activeTab,
  setActiveTab,
  setLastActiveTab,
  isDisabled,
}: SimpleTabsParameters) => {
  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
    setLastActiveTab(tab);
  };

  return (
    <Tabs index={activeTab} isFitted variant="enclosed" width="95%" color="black">
      <TabList mb="1em" id="tabs">
        <Tab onClick={() => handleTabClick(0)} className="refineTab">
          {tab1Loading && loading} Refine your Ad
        </Tab>
        <Tab onClick={() => handleTabClick(1)} className="socialMediaTab" isDisabled={isDisabled}>
          {tab2Loading && loading} Write a social post
        </Tab>
        <Tab onClick={() => handleTabClick(2)} className="outreachEmailTab" isDisabled={isDisabled}>
          {tab3Loading && loading} Outreach Email
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>{tab1}</TabPanel>
        <TabPanel p={0}>{tab2}</TabPanel>
        <TabPanel p={0}>{tab3}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SimpleTabs;
