import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { BrowserCacheLocation, InteractionRequiredAuthError, InteractionType, ProtocolMode, PublicClientApplication } from "@azure/msal-browser";

import { Generator } from "./page/generator/Generator";
import Layout from "./page/layout/Layout";
import { AuthenticatedTemplate, MsalProvider, useMsal, useMsalAuthentication } from "@azure/msal-react";

import Shepherd from "shepherd.js";
import Tour from "shepherd.js/src/types/tour";

import "./index.css";

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_CLIENT_ID,
    authority: import.meta.env.VITE_AUTHORITY,
    protocolMode: ProtocolMode.AAD,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
};

const msalRequest = { scopes: ["User.Read"] };
const msalInstance = new PublicClientApplication(msalConfig);

const initLogin = async (login: any) => {
  try {
    const response = await login(InteractionType.Redirect, msalRequest);
    return response;
  } catch (err: any) {
    throw Error(err);
  }
};

export default function App() {
  const { instance } = useMsal();
  const { login: login, error: error } = useMsalAuthentication(InteractionType.Silent, msalRequest);
  const [t, setT] = useState<Tour>();

  // the login process is as follows:
  // 1. on app load: try to silently acquire a token
  // 2. in case one does not exist, use a redirect to sign the user in (popups might be blocked)
  // 3. in case one exists, use that one
  // 4. load a user account for the acquired token so we know who is the active user
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      const res: any = initLogin(login);

      if (res === null) {
        return;
      }

      const outAcc = JSON.stringify(res.account.name);
      console.debug(`LoginPopup succeeded, setting active account: ${outAcc}`);
      instance.setActiveAccount(res.account);
    } else if (error === null) {
      const accs = instance.getAllAccounts();

      if (accs.length === 0) {
        console.warn("No login error and no active accounts (still initializing ?)");
        return;
      }

      const chosen = accs[0];
      const names = accs.map(acc => acc.name);
      const outAcc = JSON.stringify(chosen.name);
      const outAccs = JSON.stringify(names);
      console.debug(`LoginSilent succeeded, setting active account ${outAcc} from available ${outAccs}`);
      instance.setActiveAccount(chosen);
    }
  }, [error, JSON.stringify(instance)]);

  useEffect(() => {
    if (!t) {
      const tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: "shadow-md bg-purple-dark",
          scrollTo: true,
        },
      });
      setT(tour);
    }
  }, [t]);

  return (
    <Box height="100%" key={JSON.stringify(instance)}>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout tour={t} />}>
              <Route index element={<Generator tour={t}/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
    </Box>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ChakraProvider>
          <App />
      </ChakraProvider>
    </MsalProvider>
  </React.StrictMode>
);
