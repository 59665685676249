import { Flex, Icon, Text } from "@chakra-ui/react";
import { AiFillFilePdf, AiFillFileWord, AiFillCheckCircle } from "react-icons/ai";
import { FileData } from "../../api/types";

interface FileInfoProps {
  file: File | FileData;
}

export const FileInfo = (props: FileInfoProps) => {
  const { file } = props;
  const name = file?.name;
  const type = (file?.type || "").includes("pdf") ? "PDF" : "DOCX";
  const MB = ((file?.size || 0) / (1024 * 1000)).toFixed(2);
  const icon = type === "PDF" ? AiFillFilePdf : AiFillFileWord;

  return (
    <Flex
      direction="column"
      width="90%"
      margin="0 auto"
      height="6rem"
      border="2px dashed gray"
      borderColor="#335D83"
      borderRadius={5}
      alignItems="center"
      justifyContent="center"
    >
      <Flex flex={1} justifyContent="center" alignItems="center" width="100%">
        <Flex flex={1} justifyContent="center" alignItems="center">
          <Icon as={AiFillCheckCircle} w={10} h={10} color="#335D83" />
          <Icon as={icon} w={10} h={10} color="#335D83" />
          <Flex direction="column" ml={2}>
            <Text color="gray.400">{name}</Text>
            <Text color="gray.400" fontSize="small">
              File Type {type} &nbsp;|&nbsp; File Size: {MB}MB Max
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
