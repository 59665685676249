import Tour from "shepherd.js/src/types/tour";

export const LoaderTextsAd = [
  { text: "Reading your first draft.", fade: true },
  { text: "Unleashing my creativity.", fade: false },
  { text: "Writing your winning ad.", fade: false },
  { text: "Generating key words to attract the best candidates.", fade: false },
  { text: "Analysing skills for your role.", fade: false },
  { text: "Checking Robert Walters best practice.", fade: false },
  { text: "Creating a nice casual tone.", fade: false },
  { text: "Optimising your ad for appeal.", fade: false },
];

export const LoaderTextsSm = [
  { text: "Transforming your job ad for social.", fade: true },
  { text: "Highlighting key skills.", fade: false },
  { text: "Optimising for appeal.", fade: false },
  { text: "Making the post stand out.", fade: false },
  { text: "Picking the best emojis and hashtags.", fade: false },
  { text: "Keeping it short and snappy.", fade: false },
  { text: "Selecting an engaging tone.", fade: false },
];

export const LoaderTextsOe = [
  { text: "Transforming your job ad into an email.", fade: true },
  { text: "Highlighting key skills.", fade: false },
  { text: "Thinking about the candidates to attract.", fade: false },
  { text: "Selecting an engaging tone.", fade: false },
  { text: "Personalising the message.", fade: false },
  { text: "Explaining the appeal of the role.", fade: false },
];

export const TranslateOptions = () => {
  return (
    <>
      <option value="en">English</option>
      <option value="es">Spanish</option>
      <option value="de">German</option>
      <option value="fr">French</option>
      <option value="it">Italian</option>
      <option value="nl">Dutch</option>
      <option value="pt">Portuguese (Portugal)</option>
      <option value="pt-br">Portuguese (Brazil)</option>
      <option value="zh-Hant">Chinese (Traditional)</option>
      <option value="zh-Hans">Chinese (Simplified)</option>
      <option value="ko">Korean</option>
      <option value="ja">Japanese</option>
      <option value="mi">Māori</option>
    </>
  );
};

const defineButtons = (tour: Tour, id: number) => {
  if (id === 1) {
    return [
      {
        text: "Close tour",
        action: tour?.complete,
      },
      {
        text: "Next",
        action: tour?.next,
      },
    ];
  }
  if (id === 18) {
    return [
      {
        text: "Back",
        action: tour?.back,
      },
      {
        text: "Finish",
        action: tour?.complete,
      },
    ];
  }

  return [
    {
      text: "Close tour",
      action: tour?.complete,
    },
    {
      text: "Back",
      action: tour?.back,
    },
    {
      text: "Next",
      action: tour?.next,
    },
  ];
};

let options: any = {
  id: 0,
  title: "",
  text: "",
  attachTo: {
    element: "",
    on: "",
  },
  classes: "tour",
  buttons: [],
};

const steps = [
  {
    id: 1,
    title: "Start drafting your ad here.",
    text: `Click to show salary & client name if you want to maximise response.`,
    element: "#basicInfo",
    on: "right",
  },
  {
    id: 2,
    title: "",
    text: "You can choose how to have your ad written: either drop in a JOB SPEC or start your ad with your own notes or bullet points.",
    element: "#detailedInfo",
    on: "right",
  },
  { id: 3, title: "", text: "Simply click here to write your ad with AI!", element: "#writeMyAd", on: "right" },
  { id: 4, title: "Refine your Ad!", text: "Here you can refine your ad and translate it.", element: ".refineTab", on: "left" },
  {
    id: 5,
    title: "",
    text: "It will take about 90 seconds for our private version of ChatGPT to write your ad for you. Please read through and check your ad thoroughly. Remember AI can be a powerful assistant, but we humans need to use our own reasoning, fact-checking and scrutiny at all times!",
    element: ".outputAd",
    on: "left",
  },
  {
    id: 6,
    title: "",
    text: "Want to draft your ad again using a more formal or more enthusiastic and salesy tone? Simply move the slider and click to Draft again!",
    element: "#outputForm",
    on: "left",
  },
  { id: 7, title: "", text: "Translate your ad into any of our core business languages in just one click!", element: "#translate", on: "left" },
  {
    id: 8,
    title: "",
    text: "Check your ad again for accuracy before you copy and paste it to post on our website. Remember it’s still your human responsibility to make sure that the ad our Ai has written for you is absolutely accurate and the best it can be.",
    element: "#approveAndCopyAd",
    on: "left",
  },
  {
    id: 9,
    title: "Feedback",
    text: "Your feedback is valued, let us know if you are happy with the generated AD, the Social Media Post or the Outreach Email",
    element: "#ad-feedback",
    on: "left",
  },
  {
    id: 10,
    title: "",
    text: "Need a snappy social media update to show that you’re hiring now for this great role? Simply click write my post and our AI will take your ad and turn it into a super-appealing post for you to use on any social media, like LinkedIn. Copy it over, add the application link and watch the candidates come rolling in!",
    element: ".socialMediaTab",
    on: "left",
  },
  {
    id: 11,
    title: "",
    text: "Translate your social media post into any of our core business languages in just one click!  ",
    element: "#translatesm",
    on: "left",
  },
  {
    id: 12,
    title: "",
    text: "Check your social media post for accuracy before you copy and paste it over to a site like LinkedIn. Remember it’s still your human responsibility to make sure that the social media post our Ai has written for you is absolutely accurate and the best it can be. ",
    element: "#approveAndCopySm",
    on: "left",
  },
  {
    id: 13,
    title: "Write an Email!",
    text: "Click this button, and our AI will craft an email encouraging all your candidates to apply. ",
    element: ".outreachEmailTab",
    on: "left",
  },
  {
    id: 14,
    title: "Hyper-Personalize It!",
    text: "Upload your candidate’s CV, and our AI will create a tailored outreach email just for them. 🌟📨  ",
    element: "#uploadCv",
    on: "left",
  },
  { id: 15, title: "", text: "Translate your email into any of our core business languages in just one click!", element: "#translateoe", on: "left" },
  {
    id: 16,
    title: "Check your email for accuracy before you use it!",
    text: "Remember it’s still your human responsibility to make sure that the email our AI has written for you is absolutely accurate and the best it can be.",
    element: "#approveAndCopyOe",
    on: "left",
  },
  {
    id: 17,
    title: "History of ads.",
    text: "You can go back at any of your old generated ads, all inputs and outputs are saved aswell.",
    element: "#history",
    on: "right",
  },
  { id: 18, title: "And that’s the tour!", text: "Now, let’s create an amazing ad! 💪🔥", element: "main", on: "top" },
];

export const defineStpes = (tour: any) =>
  steps.map(x => {
    let step = { ...options, id: x.id, text: x.text, attachTo: { ...options.attachTo, element: x.element, on: x.on }, buttons: defineButtons(tour, x.id) };
    if (x.title) {
      step["title"] = x.title;
    } else {
      delete step.title;
    }
    return step;
  });

export const getBrowserAndDevice = (navigator: Navigator) => {
  const userAgent = navigator.userAgent;
  let device: string;
  if (/mobile/i.test(userAgent)) {
    device = "mobile";
  } else if (/tablet/i.test(userAgent)) {
    device = "tablet";
  } else {
    device = "desktop";
  }
  let browser: string;
  if (/Edg/i.test(userAgent)) {
    browser = "Edge";
  } else if (/Chrome/i.test(userAgent)) {
    browser = "Chrome";
  } else if (/Safari/i.test(userAgent)) {
    browser = "Safari";
  } else if (/Firefox/i.test(userAgent)) {
    browser = "Firefox";
  } else if (/Opera/i.test(userAgent)) {
    browser = "Opera";
  } else {
    browser = "Unknown";
  }

  return {
    browser: browser,
    device: device,
  };
};
