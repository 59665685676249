import { Component } from "react";
import Tour from "shepherd.js/src/types/tour";

// correspond to slider values
export enum Tone {
  Formal = 0,
  Casual = 1,
  Excited = 2,
}

export type AdParameters = {
  title: string;
  salary: string;
  showSalary: boolean;
  clientName: string;
  showClientName: boolean;
  location: string;
  textFromFile: string;
  summary: string;
  jobResponsibilities: string;
  jobRequirements: string;
  companySummary: string;
  howToProceed: string;
  language: string;
};

export type RefineParameterers = {
  text: string;
  tone: Tone;
  adParam: AdParameters;
};

export type GeneratorResult = {
  jsonResult: string;
  textResult: string;
  openaiOutputLanguage?: string;
  rowKey?: string;
  type?: string;
};

export type FeedbackSuccess = {
  feedback: number | null;
  success: boolean;
};

export type FileData = {
  name?: string;
  type?: string;
  size?: number;
};

export type HistoryResult = {
  partitionKey?: string;
  rowKey?: string;
  timestamp?: string;
  email?: string;
  title?: string;
  salary?: string;
  show_salary?: boolean;
  client_name?: string;
  show_client_name?: boolean;
  location?: string;
  consultant_name?: string;
  consultant_email?: string;
  summary?: string;
  job_responsibilities?: string;
  job_requirements?: string;
  company_summary?: string;
  how_to_proceed?: string;
  cv_text?: string;
  json_result?: string;
  text_result?: string;
  text_from_file?: string;
  file_data?: string;
};

export type TextRow = {
  text: string;
  fade: boolean | string;
};

export type Rows = {
  texts: TextRow[];
  loading: boolean;
  generatingText: string;
  small?: boolean;
  translate?: boolean;
  replaceText?: boolean;
};

export type TranslatorResponse = {
  text: string;
};

export type LayoutParameters = {
  tour: Tour | undefined;
};

export type GeneratorParameters = {
  tour: Tour | undefined;
};

export type SimpleTabsParameters = {
  tab1: any;
  tab2: any;
  tab3: any;
  tab1Loading: boolean;
  tab2Loading: boolean;
  tab3Loading: boolean;
  activeTab: number | undefined;
  setActiveTab: Function;
  setLastActiveTab: Function;
  isDisabled: boolean;
};

export type SideBarParameters = {
  history?: HistoryResult[];
  loading?: boolean;
  setHistoryData: Function;
  setResponse: Function;
  setFile: Function;
  setSs: Function;
  setScn: Function;
  setOriginalResponse: Function;
  setAdParameters: Function;
  setIsOpen: Function;
  refresh: Function;
  isOpen: boolean;
  isModalOpen: boolean;
};

export type AutocompleteParameters = {
  history?: HistoryResult[];
  setHistoryData: Function;
  setResponse: Function;
  setFile: Function;
  setSs: Function;
  setScn: Function;
  setOriginalResponse: Function;
  setAdParameters: Function;
};

export type MyModalParameters = {
  reactQuill: any;
  isDisabled: boolean;
  setIsModalOpen: Function;
};
