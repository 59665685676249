import { Outlet, Link } from "react-router-dom";

import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import { LayoutParameters } from "../../api/types";
import { BsBusFront } from "react-icons/bs";

import styles from "./Layout.module.css";

const Layout = ({ tour }: LayoutParameters) => {
  const { accounts } = useMsal();

  return (
    <Flex direction="column">
      <header className={styles.header}>
        <Flex className={styles.headerContainer} direction="row" align="center" gap="10" justify="space-between">
          <Flex justify="space-between" align="center">
            <Flex>
              <img src="/RWG_LOGO.png" width="100px" height="100px" />
            </Flex>
            <Link to="/" className={styles.headerTitleContainer}>
              <Text className={styles.greeting} marginLeft={5}>
                The Robert Walters Ai Ad Writer
              </Text>
            </Link>
          </Flex>
          <Flex align="center">
            <Button
              variant="solid"
              backgroundColor="transparent"
              border="1px solid white"
              _hover={{ opacity: "0.83", color: "white" }}
              margin={0}
              color="white"
              fontWeight={600}
              onClick={e => {
                e.preventDefault();
                tour?.start();
              }}
            >
              Take a tour&nbsp;&nbsp;{<Icon color="white" as={BsBusFront} w={4} h={4} />}
            </Button>
            <div style={{ background: "white", height: "3em", width: "2px", marginLeft: "10px", marginRight: "10px" }}></div>
            <Text ml="auto" fontWeight="600" color="white">
              Hello, {accounts[0]?.name}
            </Text>
          </Flex>
        </Flex>
      </header>
      <Outlet />
    </Flex>
  );
};

export default Layout;
