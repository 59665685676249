import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, useDisclosure, Button, Box, Icon } from "@chakra-ui/react";
import { AiOutlineFullscreen } from "react-icons/ai";
import { MyModalParameters } from "../../api/types";
import "./MyModal.css";

const MyModal = ({ reactQuill, isDisabled, setIsModalOpen }: MyModalParameters) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = () => {
    setIsModalOpen(true);
    onOpen();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <>
      <Button background="transparent" position="absolute" right={-2} zIndex={10000} className="fullscreen" onClick={handleOpen} isDisabled={isDisabled}>
        <Icon as={AiOutlineFullscreen} w={9} h={9} color="#335D83" />
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding={1} height="80vh">
            <Box padding={2} height="80vh" className="myModal">
              {reactQuill}
            </Box>
          </ModalBody>

          <ModalFooter padding={5}>
            <Button backgroundColor="#335D83" _hover={{ opacity: "0.93" }} onClick={handleClose} color="white" width="100%">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyModal;
