import { useEffect, useMemo, useRef, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormErrorMessage,
  Center,
  Textarea,
  Tooltip,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Grid,
  GridItem,
  Text,
  Divider,
  Icon,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import styles from "./Generator.module.css";
import { FileUploader } from "./FileUpload";
import { FileInfo } from "./FileInfo";
import {
  AdParameters,
  RefineParameterers,
  Tone,
  GeneratorResult,
  TextRow,
  Rows,
  TranslatorResponse,
  GeneratorParameters,
  HistoryResult,
  FileData,
  FeedbackSuccess,
} from "../../api/types";
import { useMsal } from "@azure/msal-react";
import * as pdfjs from "pdfjs-dist";
import { AiFillEdit, AiOutlineDelete, AiFillCheckCircle } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import Docxtemplater from "docxtemplater";
import ResizeTextarea from "react-textarea-autosize";
import { CloseIcon } from "@chakra-ui/icons";
import PizZip from "pizzip";
import ReactQuill from "react-quill";
import { FaSpinner } from "react-icons/fa6";
import { convert } from "html-to-text";
import { LoaderTextsAd, LoaderTextsOe, LoaderTextsSm, TranslateOptions, defineStpes, getBrowserAndDevice } from "./Data";
import SimpleTabs from "./SimpleTabs";
import lodash from "lodash";
import { routes } from "./Routes";
import MyModal from "./MyModal";
import Spinner from "./Spinner";
import SideBar from "./SideBar";
import { BsHandThumbsUp, BsHandThumbsDown, BsHandThumbsUpFill, BsHandThumbsDownFill } from "react-icons/bs";

import "shepherd.js/dist/css/shepherd.css";
import "react-quill/dist/quill.snow.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const mainColor = "#335D83";

const Row = (props: TextRow) => {
  return (
    <Flex justifyContent="center" alignItems="center" className={props.fade === true ? styles.fading : ""}>
      <Icon
        color={props.fade === true ? "orange" : mainColor}
        as={props.fade === "done" ? AiFillCheckCircle : props.fade ? BsStars : FaSpinner}
        w={5}
        h={5}
        marginBottom="3px"
        marginRight="10px"
        className={!props.fade ? styles.rotate : ""}
      />
      <Text className={styles.row}>{props.text}</Text>
    </Flex>
  );
};

const Generating = (props: Rows) => {
  const [_, setCt] = useState(0);
  let t: TextRow[] = [...props.texts];

  useEffect(() => {
    const interval = setInterval(() => {
      setCt(prvct => {
        const nextIndex = prvct === t.length - 1 && props.loading ? t.length - 1 : prvct === t.length - 1 ? 0 : prvct + 1;
        if (nextIndex === 0) {
          if (!props.loading) {
            clearInterval(interval);
            t[prvct].fade = "done";
          }
        } else {
          t[prvct].fade = "done";
          t[nextIndex].fade = true;
        }
        return nextIndex;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [t.length]);

  return (
    <div className={props.small ? styles.smoepreview : styles.preview} style={{ padding: "10px" }}>
      <Spinner />
      <Text
        fontSize="1.2em"
        fontWeight="500"
        color="white"
        mb={2}
        backgroundColor={mainColor}
        textAlign="center"
        padding="5px"
        margin="0 auto"
        borderRadius="5px"
      >
        {`The RWG secure AI is working on your ${props.generatingText} now...`}
      </Text>
      {props.small ? null : <br />}
      {props.small || props.translate ? (
        <Row key="sm" text={`Generating your ${props.generatingText}.`} fade={true} />
      ) : (
        t.map((x: TextRow, i: number) => <Row key={i} text={x.text.replace(" ad", ` ${props.generatingText}`)} fade={x.fade} />)
      )}
    </div>
  );
};

export const Generator = ({ tour }: GeneratorParameters) => {
  const [toneValue, setToneValue] = useState<Tone>(Tone.Casual);
  const [adParameters, setAdParameters] = useState<AdParameters>();
  const [response, setResponse] = useState<GeneratorResult>({ jsonResult: "", textResult: "", rowKey: "" });
  const [originalResponse, setOriginalResponse] = useState<GeneratorResult>({ jsonResult: "", textResult: "" });
  const [smResponse, setSmResponse] = useState<GeneratorResult>({ jsonResult: "", textResult: "", rowKey: "" });
  const [originalSmResponse, setOriginalSmResponse] = useState<GeneratorResult>({ jsonResult: "", textResult: "" });
  const [oeResponse, setOeResponse] = useState<GeneratorResult>({ jsonResult: "", textResult: "", rowKey: "" });
  const [originalOeResponse, setOriginalOeResponse] = useState<GeneratorResult>({ jsonResult: "", textResult: "" });
  const [fileText, setFileText] = useState<string>("");
  const [fileCVText, setFileCVText] = useState<string>("");
  const [file, setFile] = useState<File | FileData>();
  const [fileCV, setFileCV] = useState<File>();
  const [expand, setExpand] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [smLoading, setSmLoading] = useState<boolean>(false);
  const [oeLoading, setOeLoading] = useState<boolean>(false);
  const [fbLoading, setFbLoading] = useState<boolean>(false);
  const [fbSmLoading, setFbSmLoading] = useState<boolean>(false);
  const [fbOeLoading, setFbOeLoading] = useState<boolean>(false);
  const [translating, setTranslating] = useState<boolean>(false);
  const [tabsLocked, setTabsLocked] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [lastActiveTab, setLastActiveTab] = useState<number>(0);
  const [initialLanguage, setInitialLanguage] = useState<string>("");
  const [smInitialLanguage, setSmInitialLanguage] = useState<string>("");
  const [oeInitialLanguage, setOeInitialLanguage] = useState<string>("");
  const [history, setHistory] = useState<HistoryResult[]>();
  const [hsLoading, setHsLoading] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<HistoryResult>({});
  const [ss, setSs] = useState<boolean>(false);
  const [scn, setScn] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [fbSuccess, setFbSuccess] = useState<FeedbackSuccess>({ feedback: null, success: false });
  const [fbSmSuccess, setFbSmSuccess] = useState<FeedbackSuccess>({ feedback: null, success: false });
  const [fbOeSuccess, setFbOeSuccess] = useState<FeedbackSuccess>({ feedback: null, success: false });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { instance, accounts } = useMsal();

  const initialized = useRef<boolean>(false);
  const translateAdValue = useRef<string>("");
  const translateSmValue = useRef<string>("");
  const translateOeValue = useRef<string>("");

  const getHistory = async () => {
    try {
      setHsLoading(true);
      const authResponse = await instance.acquireTokenSilent({ scopes: ["User.Read", "email", "openid", "profile"] });
      const token = authResponse.accessToken;
      const opts = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      };
      const response = await fetch(routes.history, opts);
      const historyResult = (await response.json()) as HistoryResult[];
      setHistory(historyResult);
    } catch (err) {
      let description: string = "";
      if (err instanceof Error) {
        description = err.message;
      } else {
        description = "Something went wrong with your history ads, please try again.";
      }
      if (accounts.length && description.includes("No account object provided to acquireTokenSilent")) return;
      toast({
        title: "Error",
        description,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setHsLoading(false);
  };

  useEffect(() => {
    if (!initialized.current && getHistory && accounts.length) {
      initialized.current = true;
      getHistory();
    }
  }, [accounts]);

  useEffect(() => {
    if (tour) {
      tour?.on("show", (e: any) => {
        if (e.step.id === 9 || e.step.id === 5) {
          setTabsLocked(false);
          setActiveTab(0);
        }
        if (e.step.id === 10 || e.step.id === 12) {
          setTabsLocked(false);
          setActiveTab(1);
        }
        if (e.step.id === 13) {
          setTabsLocked(false);
          setActiveTab(2);
        }
        if (e.step.id === 17) {
          setIsOpen(true);
        }
      });
      tour.on("complete", () => {
        setActiveTab(lastActiveTab);
        if (!response.textResult) {
          setTabsLocked(true);
        }
      });
      const stps = defineStpes(tour);
      tour.addSteps(stps);
    }
  }, [tour]);
  const toast = useToast();

  const {
    register: formRegister,
    handleSubmit: formHandleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<AdParameters>();

  const sliderMarkStyles = {
    mt: "5px",
    ml: "-20px",
  };

  const generate = async (data: any, sm: boolean = false, oe: boolean = false) => {
    let d = { ...data } as any;
    let description: string = "";
    let generation_type: string = "";
    try {
      if (!sm && !oe) {
        setLoading(true);
        generation_type = "job-ad-generation";
        d.title = !data?.title && historyData?.title ? historyData?.title : data?.title;
        d.location = !data?.location && historyData?.location ? historyData?.location : data?.location;
        d.salary = !data?.salary && historyData?.salary ? historyData?.salary : data?.salary;
        d.showSalary = ss;
        d.clientName = !data?.clientName && historyData?.client_name ? historyData?.client_name : data?.clientName;
        d.showClientName = scn;
        d.summary = !data?.summary && historyData?.summary ? historyData?.summary : data?.summary;
        d.jobResponsibilities = !data?.jobResponsibilities && historyData?.job_responsibilities ? historyData?.job_responsibilities : data?.jobResponsibilities;
        d.jobRequirements = !data?.jobRequirements && historyData?.job_requirements ? historyData?.job_requirements : data?.jobRequirements;
        d.companySummary = !data?.companySummary && historyData?.company_summary ? historyData?.company_summary : data?.companySummary;
        d.howToProceed = !data?.howToProceed && historyData?.how_to_proceed ? historyData?.how_to_proceed : data?.howToProceed;
        if (file) {
          d.fileData = JSON.stringify({ name: file?.name, type: file?.type, size: file?.size });
        }
        if (!fileText && !historyData?.text_from_file && d.textFromFile) {
          delete d.textFromFile;
        }
      }
      if (sm) {
        setSmLoading(true);
        d["socialMedia"] = htmlToText(d.socialMedia);
        generation_type = "social-media-post-generation";
      }
      if (oe) {
        setOeLoading(true);
        d["outreachEmail"] = htmlToText(d.outreachEmail);
        generation_type = "outreach-email-generation";
      }
      const body = JSON.stringify(d);
      const authResponse = await instance.acquireTokenSilent({ scopes: ["User.Read", "email", "openid", "profile"] });
      const token = authResponse.accessToken;
      const { browser, device } = getBrowserAndDevice(navigator);
      const opts = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          browser,
          device,
          generation_type,
        },
        body,
      };
      const response = await fetch(routes.openai, opts);
      const generatorResult = (await response.json()) as GeneratorResult;
      if (!sm && !oe) {
        setResponse(generatorResult);
        description = "Your ad is ready.";
        setOriginalResponse(generatorResult);
        if (!initialLanguage) {
          setInitialLanguage(generatorResult.openaiOutputLanguage || "");
        }
      }
      if (sm) {
        setSmResponse(generatorResult);
        description = "Your social media post is ready.";
        setOriginalSmResponse(generatorResult);
        if (!smInitialLanguage) {
          setSmInitialLanguage(generatorResult.openaiOutputLanguage || "");
        }
      }
      if (oe) {
        setOeResponse(generatorResult);
        description = "Your outreach email is ready.";
        setOriginalOeResponse(generatorResult);
        if (!oeInitialLanguage) {
          setOeInitialLanguage(generatorResult.openaiOutputLanguage || "");
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        description = err.message;
      } else {
        description = "Something went wrong, please try again.";
      }
      toast({
        title: "Error",
        description,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    toast({
      title: "Success",
      description,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setLoading(false);
    setSmLoading(false);
    setOeLoading(false);
  };

  const translate = async (language: string, generation_type: string) => {
    let text: string = "";
    let description: string = "";
    let type: string = "";
    if (language === initialLanguage && generation_type === "ad") {
      setResponse({ ...originalResponse });
      return;
    }
    if (language === smInitialLanguage && generation_type === "sm") {
      setSmResponse({ ...originalSmResponse });
      return;
    }
    if (language === oeInitialLanguage && generation_type === "oe") {
      setOeResponse({ ...originalOeResponse });
      return;
    }
    if (generation_type === "ad") {
      text = originalResponse.textResult;
      type = "translation-job-ad";
    }
    if (generation_type === "sm") {
      text = smResponse.textResult;
      type = "translation-social-media-post";
    }
    if (generation_type === "oe") {
      text = oeResponse.textResult;
      type = "translation-outreach-email";
    }
    try {
      setTranslating(true);
      const body = JSON.stringify({ text, language, type });
      const authResponse = await instance.acquireTokenSilent({ scopes: ["User.Read"] });
      const token = authResponse.accessToken;
      const { browser, device } = getBrowserAndDevice(navigator);
      const opts = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          browser,
          device,
        },
        body,
      };
      const r = await fetch(routes.translate, opts);
      const translatorResult = (await r.json()) as TranslatorResponse;
      if (generation_type === "ad") {
        setResponse(pv => ({ ...pv, textResult: translatorResult.text }));
        description = "Your ad has been translated.";
      }
      if (generation_type === "sm") {
        setSmResponse(pv => ({ ...pv, textResult: translatorResult.text }));
        description = "Your social media post has been translated.";
      }
      if (generation_type === "oe") {
        setOeResponse(pv => ({ ...pv, textResult: translatorResult.text }));
        description = "Your outreach email has been translated.";
      }
    } catch (err) {
      if (err instanceof Error) {
        description = err.message;
      } else {
        description = "Something went wrong, please try again.";
      }
      toast({
        title: "Error",
        description,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    toast({
      title: "Success",
      description,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setTranslating(false);
  };

  const handleAskForm: SubmitHandler<AdParameters> = async data => {
    await generate(data);
    setExpand(false);
    setAdParameters(data);
  };

  const handleRefineForm = async (e: any) => {
    e.preventDefault();
    if (!adParameters) {
      return;
    }
    const data: RefineParameterers = { text: response.jsonResult, tone: toneValue, adParam: adParameters };
    await generate(data);
    setExpand(false);
  };

  const handleFeedback = async (feedback: number, adType: string) => {
    let description: string = "";
    let metricsRowKey: string = "";
    let type: string = "";
    if (response?.rowKey || smResponse?.rowKey || oeResponse?.rowKey) {
      if (adType === "ad-feedback") {
        metricsRowKey = response?.rowKey || "";
        type = response?.type || "";
        setFbLoading(true);
      }
      if (adType === "sm-feedback") {
        metricsRowKey = smResponse?.rowKey || "";
        type = smResponse?.type || "";
        setFbSmLoading(true);
      }
      if (adType === "oe-feedback") {
        metricsRowKey = oeResponse?.rowKey || "";
        type = oeResponse?.type || "";
        setFbOeLoading(true);
      }
      try {
        const body = JSON.stringify({ feedback, metricsRowKey, type });
        const authResponse = await instance.acquireTokenSilent({ scopes: ["User.Read", "email", "openid", "profile"] });
        const token = authResponse.accessToken;
        const opts = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body,
        };
        await fetch(routes.feedback, opts);
        if (adType === "ad-feedback") setFbSuccess({ feedback, success: true });
        if (adType === "sm-feedback") setFbSmSuccess({ feedback, success: true });
        if (adType === "oe-feedback") setFbOeSuccess({ feedback, success: true });
        description = "Feedback saved successfully.";
      } catch (err) {
        if (err instanceof Error) {
          description = err.message;
        } else {
          description = "Something went wrong, please try again.";
        }
        toast({
          title: "Error",
          description,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: "Success",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFbLoading(false);
      setFbSmLoading(false);
      setFbOeLoading(false);
    }
  };

  const handleFileUpload = async (file: File, cv: boolean = false) => {
    if (cv) {
      setFileCV(file);
    } else {
      setFile(file);
    }
    if (file.type === "application/pdf") {
      let text = "";
      const buf = await file.arrayBuffer();
      const pdf = await pdfjs.getDocument({ data: buf }).promise;

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const content = await page.getTextContent();
        // @ts-ignore
        text += content.items.map(item => item.str).join(" ");
      }

      if (cv) {
        setFileCVText(text);
      } else {
        setFileText(text);
      }
    }
    if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      const reader = new FileReader();
      reader.onload = function (e) {
        // @ts-ignore
        const zip = new PizZip(e.target.result);
        const doc = new Docxtemplater().loadZip(zip);
        const text = doc.getFullText();
        if (cv) {
          setFileCVText(text);
        } else {
          setFileText(text);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const copyGeneratedText = (sm = false, oe = false) => {
    let checkResponse = response;
    if (sm) {
      checkResponse = smResponse;
    }
    if (oe) {
      checkResponse = oeResponse;
    }
    if (checkResponse != null && checkResponse.textResult != "") {
      const options = {
        wordwrap: 0,
      };
      const text = convert(checkResponse.textResult, options);
      navigator.clipboard.writeText(text);
    }
  };

  const htmlToText = (html: string) => {
    const options = {
      wordwrap: 0,
    };
    const converted = convert(html, options);
    const text = converted;
    return text;
  };

  const handleExpandMenu = (e: any) => {
    e.preventDefault();
    setExpand(true);
  };

  const refresh = (closeExpand: boolean = true) => {
    setToneValue(Tone.Casual);
    setAdParameters(undefined);
    setResponse({ jsonResult: "", textResult: "", rowKey: "" });
    setOriginalResponse({ jsonResult: "", textResult: "" });
    setSmResponse({ jsonResult: "", textResult: "", rowKey: "" });
    setOriginalSmResponse({ jsonResult: "", textResult: "" });
    setOeResponse({ jsonResult: "", textResult: "", rowKey: "" });
    setOriginalOeResponse({ jsonResult: "", textResult: "" });
    setFileText("");
    setFileCVText("");
    setFile(undefined);
    setFileCV(undefined);
    setLoading(false);
    setSmLoading(false);
    setOeLoading(false);
    setTranslating(false);
    setTabsLocked(true);
    setActiveTab(0);
    setLastActiveTab(0);
    setInitialLanguage("");
    setSmInitialLanguage("");
    setOeInitialLanguage("");
    setHsLoading(false);
    setHistoryData({});
    setSs(false);
    setScn(false);
    setFbLoading(false);
    setFbSuccess({ feedback: null, success: false });
    if (closeExpand) {
      setExpand(false);
    }
    reset();
  };

  const createFormOptions = () => {
    return (
      <Flex direction="column">
        <FormControl isRequired isInvalid={errors.title != null}>
          <Tooltip label="Enter a concise, clear title for the role. Avoid abbreviations. Example: 'Senior Software Engineer'.">
            <FormLabel>Job Title ⓘ</FormLabel>
          </Tooltip>
          <Input tabIndex={1} {...formRegister("title")} type="text" defaultValue={historyData?.title} />
          <FormErrorMessage>A title is required</FormErrorMessage>
        </FormControl>

        <Box h="1rem" />

        <FormControl isInvalid={errors.salary != null}>
          <Tooltip label="Enter the salary range or benefits for this role. If not applicable, leave it blank and we will default to 'competitive'.">
            <FormLabel>Salary ⓘ</FormLabel>
          </Tooltip>
          <Input tabIndex={3} {...formRegister("salary")} type="text" defaultValue={historyData?.salary} />
          <FormErrorMessage>A salary is required</FormErrorMessage>
        </FormControl>

        <Box h="0.5rem" />

        <Checkbox tabIndex={4} {...formRegister("showSalary")} onChange={e => setSs(e.target.checked)} isChecked={ss}>
          Show Salary
        </Checkbox>
      </Flex>
    );
  };

  const createManualForm = () => {
    return (
      <Flex direction="column">
        <FormControl isRequired isInvalid={errors.location != null}>
          <FormLabel>Location</FormLabel>
          <Input tabIndex={2} {...formRegister("location")} type="text" defaultValue={historyData?.location} />
          <FormErrorMessage>A location is required</FormErrorMessage>
        </FormControl>

        <Box h="1rem" />

        <FormControl isRequired isInvalid={errors.clientName != null}>
          <FormLabel>Client Name</FormLabel>
          <Input tabIndex={5} {...formRegister("clientName")} type="text" defaultValue={historyData?.client_name} />
          <FormErrorMessage>A client name is required</FormErrorMessage>
        </FormControl>

        <Box h="0.5rem" />

        <Checkbox tabIndex={6} {...formRegister("showClientName")} onChange={e => setScn(e.target.checked)} isChecked={scn}>
          Show Client Name
        </Checkbox>
      </Flex>
    );
  };

  const createExtractorForm = () => {
    return (
      <>
        <Flex direction="column">
          {fileText || historyData?.text_from_file ? (
            <Flex direction="column" className={styles.border} mt={2}>
              <Textarea
                hidden={true}
                {...formRegister("textFromFile")}
                variant="outline"
                value={fileText || historyData.text_from_file}
                as={ResizeTextarea}
                onChange={e => setFileText(e.target.value)}
              />
              {file && <FileInfo file={file} />}
              <Box h="1rem" />
              <Button
                w="4rem"
                variant="outline"
                marginLeft="auto"
                color="red"
                _hover={{ opacity: "0.93" }}
                colorScheme="#ff4d66"
                onClick={_ => {
                  setFile(undefined);
                  setFileText("");
                  setHistoryData(ps => ({ ...ps, text_from_file: "" }));
                }}
              >
                <Icon as={AiOutlineDelete} w={5} h={5} />
              </Button>
            </Flex>
          ) : (
            <FileUploader onFileUpload={handleFileUpload} isCv={false} key="fileuploader" />
          )}
        </Flex>
      </>
    );
  };

  const createExpandMenu = () => {
    return (
      <>
        <Box h="1rem" />
        <Flex direction="column" className={styles.border}>
          <Button
            alignSelf="self-end"
            w="4rem"
            variant="outline"
            onClick={() => setExpand(false)}
            color="red"
            _hover={{ opacity: "0.93" }}
            colorScheme="#ff4d66"
          >
            <Icon as={CloseIcon} w={5} h={5} />
          </Button>
          <FormControl isInvalid={errors.summary != null}>
            <Tooltip label="Highlight the top three selling points of the role that would entice a potential candidate. These could be unique benefits, opportunities, or aspects of the role.">
              <FormLabel>Job Summary ⓘ</FormLabel>
            </Tooltip>
            <Textarea className={styles.inputTextArea} {...formRegister("summary")} defaultValue={historyData?.summary} key={historyData?.summary} />
            <FormErrorMessage>A summary is required</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.jobResponsibilities != null}>
            <Tooltip label="Describe the primary responsibilities and tasks the candidate will be undertaking in this role. Highlight how they will contribute to the overall objectives of the team/company.">
              <FormLabel>What the Applicant Will Do ⓘ</FormLabel>
            </Tooltip>
            <Textarea
              className={styles.inputTextArea}
              {...formRegister("jobResponsibilities")}
              defaultValue={historyData?.job_responsibilities}
              key={historyData?.job_responsibilities}
            />
            <FormErrorMessage>'What the Applicant Will Do' is required</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.jobRequirements != null}>
            <Tooltip label="Describe the necessary skills, experience, and qualifications needed for this role. Distinguish between 'mandatory' and 'desirable'.">
              <FormLabel>What the Applicant Will Bring ⓘ</FormLabel>
            </Tooltip>
            <Textarea
              className={styles.inputTextArea}
              {...formRegister("jobRequirements")}
              defaultValue={historyData?.job_requirements}
              key={historyData?.job_requirements}
            />
            <FormErrorMessage>'What the Applicant Will Bring' is required</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.companySummary != null}>
            <Tooltip label="Describe what makes this company a unique and desirable employer. This could include the company's mission, culture, benefits, etc.">
              <FormLabel>What Sets the Client Company Apart ⓘ</FormLabel>
            </Tooltip>
            <Textarea
              className={styles.inputTextArea}
              {...formRegister("companySummary")}
              defaultValue={historyData?.company_summary}
              key={historyData?.company_summary}
            />
            <FormErrorMessage>'What Sets the Client Company Apart' is required</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.howToProceed != null}>
            <Tooltip label="Provide instructions for how to apply, what to expect in the next steps, and any compelling calls-to-action to encourage applications.">
              <FormLabel>What's Next for the Applicant ⓘ</FormLabel>
            </Tooltip>
            <Textarea
              className={styles.inputTextArea}
              {...formRegister("howToProceed")}
              defaultValue={historyData?.how_to_proceed}
              key={historyData?.how_to_proceed}
            />
            <FormErrorMessage>'What's Next for the Applicant' is required</FormErrorMessage>
          </FormControl>
        </Flex>
        <Box h="4rem" />
      </>
    );
  };

  const formOptions = createFormOptions();
  const manualForm = createManualForm();
  const extractorForm = createExtractorForm();
  const expandMenu = createExpandMenu();

  const createInputForm = () => {
    return (
      <form id="inputForm" onSubmit={formHandleSubmit(handleAskForm)} className={styles.form}>
        <Text fontSize="1.4em" fontWeight="500" mb={2}>
          Draft your ad
        </Text>
        <Grid templateColumns="repeat(2, auto)" gap={6} w="100%" padding={2} id="basicInfo">
          <GridItem w="100%">{formOptions}</GridItem>
          <GridItem w="100%">{manualForm}</GridItem>
        </Grid>
        <Box id="detailedInfo">
          {!file && !fileText && !expand && (
            <Text mt={5} mb={5} textAlign="center" fontSize={isOpen ? "13px" : "16px"}>
              <b>Customise your ad &nbsp;</b> <b style={{ color: mainColor }}>ⓘ</b> Choose how you'd like to add more details with the help of AI
            </Text>
          )}
          {!expand && extractorForm}
          {!file && !fileText && !expand && (
            <>
              <Flex justifyContent="space-between" alignItems="center" width="88%" margin="10px auto">
                <Center width="45%" borderColor={mainColor} borderWidth="1px">
                  <Divider orientation="horizontal" />
                </Center>
                <Text color={mainColor}>Or</Text>
                <Center width="45%" borderColor={mainColor} borderWidth="1px">
                  <Divider orientation="horizontal" />
                </Center>
              </Flex>
              <Flex
                direction="column"
                width="90%"
                margin="0 auto"
                height="6rem"
                border="2px dashed gray"
                borderColor={mainColor}
                borderRadius={5}
                alignItems="center"
                justifyContent="center"
                tabIndex={8}
              >
                <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
                  <Icon as={AiFillEdit} w={10} h={10} color={mainColor} />
                  <Text color="gray.400">
                    {
                      <a href="#" onClick={handleExpandMenu} style={{ color: mainColor }}>
                        Start your ad with a few notes
                      </a>
                    }
                  </Text>
                </Flex>
              </Flex>
            </>
          )}
          {expand && expandMenu}
        </Box>
      </form>
    );
  };

  const createFeedbackButtons = (id: string, disabled: boolean, feedbackSuccess: FeedbackSuccess, loading: boolean, isModalOpen: boolean) => {
    return (
      <Flex justifyContent="space-between" alignItems="center" className={styles.whiteBg} width="100%" id={id} pl={3.5} pr={3.5}>
        <Text fontWeight="500">Give us your feedback</Text>
        <div>
          {loading ? (
            <Spinner small={true} />
          ) : (
            <>
              <Button
                marginRight={1}
                padding={1}
                zIndex={10000}
                backgroundColor={isModalOpen ? "transparent" : "white"}
                onClick={() => handleFeedback(1, id)}
                isDisabled={disabled}
              >
                <Icon
                  as={feedbackSuccess["success"] && feedbackSuccess["feedback"] ? BsHandThumbsUpFill : BsHandThumbsUp}
                  w={6}
                  h={6}
                  color={mainColor}
                  _hover={{ opacity: "0.83" }}
                />
              </Button>
              <Button
                padding={1}
                zIndex={10000}
                backgroundColor={isModalOpen ? "transparent" : "white"}
                onClick={() => handleFeedback(0, id)}
                isDisabled={disabled}
              >
                <Icon
                  as={feedbackSuccess["success"] && !feedbackSuccess["feedback"] ? BsHandThumbsDownFill : BsHandThumbsDown}
                  w={6}
                  h={6}
                  color={mainColor}
                  _hover={{ opacity: "0.83" }}
                />
              </Button>
            </>
          )}
        </div>
      </Flex>
    );
  };

  const adQuill = useMemo(
    () => (
      <ReactQuill
        modules={{ toolbar: false }}
        className={styles.preview}
        placeholder="Your job ad will appear here!"
        readOnly={true}
        value={response.textResult}
      />
    ),
    [response?.textResult]
  );

  const smQuill = useMemo(
    () => (
      <ReactQuill
        modules={{ toolbar: false }}
        className={styles.preview}
        placeholder="“Click ‘Write My Post’! 
            
Our AI turns your job ad into an eye-catching social media update. 
Add the application link, and watch the candidates roll in!” 🚀👀"
        readOnly={true}
        value={smResponse.textResult}
      />
    ),
    [smResponse?.textResult]
  );

  const oeQuill = useMemo(
    () => (
      <ReactQuill
        modules={{ toolbar: false }}
        className={styles.preview}
        placeholder="Need an email to send to all your candidates asking them to apply to this role?  Simply click ‘Write my email’ and our AI will write you an email to encourage them to apply. 
      or
Want to personalise just one perfect outreach email at a time? Upload your candidate’s CV and our AI will hyper-personalise the perfect outreach email, just for that one candidate. "
        readOnly={true}
        value={oeResponse.textResult}
      />
    ),
    [oeResponse?.textResult]
  );

  const createOutputForm = () => {
    return (
      <Flex direction="column" w="100%" key="adf">
        {loading || translating ? (
          <Generating
            texts={lodash.cloneDeep(LoaderTextsAd)}
            loading={loading || translating}
            key={Date.now()}
            generatingText={translating ? "translation" : "ad"}
            translate={translating}
          />
        ) : (
          <Box width="100%" className="outputAd">
            <MyModal reactQuill={adQuill} isDisabled={!response.textResult || loading} setIsModalOpen={setIsModalOpen} />
            {adQuill}
          </Box>
        )}
        <Box mt="0.5rem" />
        <Box>
          <form id="outputForm" onSubmit={handleRefineForm}>
            <FormControl className={styles.whiteBg} p={4}>
              <FormLabel mb={0}>Adjust tone</FormLabel>
              <Flex justifyContent="space-around" pt={2} pb={7}>
                <Slider
                  isDisabled={!response.textResult || loading}
                  mt="1rem"
                  w={isOpen ? "15rem" : "17rem"}
                  defaultValue={toneValue}
                  min={Tone.Formal}
                  max={Tone.Excited}
                  step={1}
                  onChange={v => setToneValue(v)}
                >
                  <SliderMark {...sliderMarkStyles} value={Tone.Formal} mt={3}>
                    Formal
                  </SliderMark>
                  <SliderMark {...sliderMarkStyles} value={Tone.Casual} mt={3}>
                    Casual
                  </SliderMark>
                  <SliderMark {...sliderMarkStyles} value={Tone.Excited} mt={3}>
                    Enthusiastic
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack bg={mainColor} />
                  </SliderTrack>
                  <SliderThumb border="1px solid lightgray" width="17px" height="17px">
                    <Box color="orange" as={BsStars} borderRadius="50%" />
                  </SliderThumb>
                </Slider>
                <Flex direction="row" justifyContent="flex-end" style={{ marginLeft: "4rem" }}>
                  <Button
                    variant="solid"
                    backgroundColor={mainColor}
                    _hover={{ opacity: "0.93" }}
                    color="white"
                    formTarget="outputForm"
                    type="submit"
                    minW="auto"
                    isLoading={isSubmitting}
                    isDisabled={toneValue === 1 || loading || smLoading || oeLoading || translating}
                  >
                    {<Icon color="orange" as={BsStars} w={5} h={5} />}&nbsp;Draft again
                  </Button>
                </Flex>
              </Flex>
            </FormControl>
          </form>
        </Box>
        <Box mt="0.5rem" />
        <Flex justifyContent="flex-start" alignItems="center" className={styles.whiteBg} pl={3.5} pr={5} id="translate">
          <Text fontWeight="500">Translate your ad</Text>
          <Select
            marginLeft={5}
            isDisabled={!response.textResult || loading || smLoading || oeLoading || translating}
            width="35%"
            variant="outline"
            placeholder="Choose language"
            onChange={e => (translateAdValue.current = e.target.value)}
          >
            <TranslateOptions />
          </Select>
          <Button
            marginLeft="auto"
            variant="solid"
            backgroundColor={mainColor}
            _hover={{ opacity: "0.93" }}
            color="white"
            onClick={() => translate(translateAdValue.current, "ad")}
            isDisabled={!response.textResult || loading || smLoading || oeLoading || translating}
          >
            Translate
          </Button>
        </Flex>
        <Box mt="0.5rem" />
        <Flex direction="row" justifyContent="space-arround" className={styles.whiteBg} id="approveAndCopyAd">
          <Flex direction="row" alignItems="center" justifyContent="center">
            <Text fontWeight="900" fontSize="1.3rem" mr={1} color={mainColor}>
              ⓘ
            </Text>
            <Text fontWeight="600" textAlign="left" lineHeight="1rem" fontSize="0.9rem" width="90%">
              This <span style={{ fontWeight: "700", color: mainColor }}>ad</span> is AI generated, so make sure it's accurate and appropriate before using it.
            </Text>
          </Flex>
          <Button
            variant="solid"
            backgroundColor={mainColor}
            _hover={{ opacity: "0.93" }}
            color="white"
            isDisabled={!response.textResult || loading || translating}
            onClick={() => {
              copyGeneratedText();
            }}
            pl={8}
            pr={8}
            className={styles.approveAndCopy}
          >
            Approve and copy
          </Button>
        </Flex>
        <Box mt="0.5rem" />
        {createFeedbackButtons("ad-feedback", !response?.rowKey || fbLoading || fbSuccess.success, fbSuccess, fbLoading, isModalOpen)}
      </Flex>
    );
  };

  const createSocialMediaForm = () => {
    return (
      <Flex direction="column" w="100%" key="smf">
        {smLoading || translating ? (
          <Generating
            texts={lodash.cloneDeep(LoaderTextsSm)}
            loading={smLoading}
            key={Date.now()}
            generatingText={translating ? "translation" : "social media post"}
            replaceText={true}
            translate={translating}
          />
        ) : (
          smQuill
        )}
        <Box mt="1rem" />
        <Button
          variant="solid"
          backgroundColor={mainColor}
          _hover={{ opacity: "0.93" }}
          color="white"
          width="70%"
          margin="0 auto"
          isDisabled={!response.textResult || loading || smLoading || oeLoading || translating}
          isLoading={smLoading}
          onClick={() => {
            generate(
              {
                socialMedia: response.textResult,
              },
              true
            );
          }}
          pl={8}
          pr={8}
        >
          {<Icon color="orange" as={BsStars} w={5} h={5} />}&nbsp;{smResponse.textResult ? "Draft again" : "Write my post"}
        </Button>
        <Box mt="1.5rem" />
        <Flex justifyContent="flex-start" alignItems="center" className={styles.whiteBg} pl={3.5} pr={5} id="translatesm">
          <Text fontWeight="500" fontSize={isOpen ? "12" : "16"}>
            Translate your social media post
          </Text>
          <Select
            marginLeft={isOpen ? 2 : 5}
            isDisabled={!smResponse.textResult || loading || smLoading || oeLoading || translating}
            width="37%"
            variant="outline"
            placeholder="Choose language"
            onChange={e => (translateSmValue.current = e.target.value)}
          >
            <TranslateOptions />
          </Select>
          <Button
            marginLeft="auto"
            variant="solid"
            backgroundColor={mainColor}
            _hover={{ opacity: "0.93" }}
            color="white"
            onClick={() => translate(translateSmValue.current, "sm")}
            isDisabled={!smResponse.textResult || loading || smLoading || oeLoading || translating}
          >
            Translate
          </Button>
        </Flex>
        <Box mt="0.5rem" />
        <Flex direction="row" justifyContent="space-arround" className={styles.whiteBg} id="approveAndCopySm">
          <Flex direction="row" alignItems="center" justifyContent="center">
            <Text fontWeight="900" fontSize="1.3rem" mr={1} color={mainColor}>
              ⓘ
            </Text>
            <Text fontWeight="600" textAlign="left" lineHeight="1rem" fontSize="0.9rem" width="90%">
              This <span style={{ fontWeight: "700", color: mainColor }}>social media</span> is AI generated, so make sure it's accurate and appropriate before
              using it.
            </Text>
          </Flex>
          <Button
            mt={2}
            variant="solid"
            backgroundColor={mainColor}
            _hover={{ opacity: "0.93" }}
            color="white"
            isDisabled={!smResponse.textResult || smLoading || translating}
            onClick={() => {
              copyGeneratedText(true);
            }}
            pl={8}
            pr={8}
            className={styles.approveAndCopy}
          >
            Approve and copy
          </Button>
        </Flex>
        <Box mt="0.5rem" />
        {createFeedbackButtons("sm-feedback", !smResponse?.rowKey || fbSmLoading || fbSmSuccess.success, fbSmSuccess, fbSmLoading, isModalOpen)}
      </Flex>
    );
  };

  const createOutreachEmailForm = () => {
    return (
      <Flex direction="column" w="100%" align="center" key="oef">
        {oeLoading || translating ? (
          <Generating
            texts={lodash.cloneDeep(LoaderTextsOe)}
            loading={oeLoading}
            key={Date.now()}
            generatingText={translating ? "translation" : "outreach email"}
            replaceText={true}
            translate={translating}
          />
        ) : (
          oeQuill
        )}
        <Box mt="0.5rem" />
        <Flex direction="row" justifyContent="space-between" alignItems="center" className={styles.whiteBg} width="100%" pl={5} id="uploadCv">
          <Text width="62%">
            {fileCV ? (
              <i style={{ marginTop: "6px", marginBottom: "10px", fontWeight: "600", color: "green" }}>Your outreach email will be tailored to this CV!</i>
            ) : (
              <i style={{ marginTop: "6px", marginBottom: "10px", fontWeight: "600", color: "#ff4d66", fontSize: isOpen ? "14px" : "16px" }}>
                Personalise your email by uploading a CV!
              </i>
            )}
          </Text>
          <Box width="37%">
            {fileCV ? (
              <Button
                width="100%"
                variant="solid"
                backgroundColor="white"
                _hover={{ opacity: "0.75" }}
                border="1px solid red"
                color="#ff4d66"
                pl={8}
                pr={8}
                onClick={() => {
                  setFileCV(undefined);
                  setFileCVText("");
                }}
              >
                Remove CV
              </Button>
            ) : (
              <FileUploader
                onFileUpload={handleFileUpload}
                isCv={true}
                isDisabled={!response.textResult || loading || smLoading || oeLoading || translating}
                key="cvuploader"
              />
            )}
          </Box>
        </Flex>
        <Box mt="0.5rem" />
        <Button
          mt={2}
          variant="solid"
          backgroundColor={mainColor}
          _hover={{ opacity: "0.93" }}
          width="70%"
          color="white"
          isDisabled={!response.textResult || loading || smLoading || oeLoading || translating}
          isLoading={oeLoading}
          onClick={() => {
            generate(
              {
                outreachEmail: response.textResult,
                cvText: fileCVText,
              },
              false,
              true
            );
          }}
          pl={8}
          pr={8}
          mr={2}
        >
          {<Icon color="orange" as={BsStars} w={5} h={5} />}&nbsp;{oeResponse.textResult ? "Draft again" : "Write my email"}
        </Button>
        <Box mt="1.5rem" />
        <Flex justifyContent="flex-start" alignItems="center" className={styles.whiteBg} pl={3.5} pr={5} width="100%" id="translateoe">
          <Text fontWeight="500" fontSize={isOpen ? "14" : "15"}>
            Translate your outreach email
          </Text>
          <Select
            marginLeft={isOpen ? 2 : 5}
            isDisabled={!oeResponse.textResult || loading || smLoading || oeLoading || translating}
            width="37%"
            variant="outline"
            placeholder="Choose language"
            onChange={e => (translateOeValue.current = e.target.value)}
          >
            <TranslateOptions />
          </Select>
          <Button
            marginLeft="auto"
            variant="solid"
            backgroundColor={mainColor}
            _hover={{ opacity: "0.93" }}
            color="white"
            onClick={() => translate(translateOeValue.current, "oe")}
            isDisabled={!oeResponse.textResult || loading || smLoading || oeLoading || translating}
          >
            Translate
          </Button>
        </Flex>
        <Box mt="0.5rem" />
        <Flex direction="row" justifyContent="space-arround" className={styles.whiteBg} id="approveAndCopyOe">
          <Flex direction="row" alignItems="center" justifyContent="center">
            <Text fontWeight="900" fontSize="1.3rem" mr={1} color={mainColor}>
              ⓘ
            </Text>
            <Text fontWeight="600" textAlign="left" lineHeight="1rem" fontSize={isOpen ? "0.85rem" : "0.9rem"} width="90%">
              This <span style={{ fontWeight: "700", color: mainColor }}>outreach email</span> is AI generated, so make sure it's accurate and appropriate
              before using it.
            </Text>
          </Flex>
          <Button
            mt={2}
            variant="solid"
            backgroundColor={mainColor}
            _hover={{ opacity: "0.93" }}
            color="white"
            isDisabled={!oeResponse.textResult || oeLoading || translating}
            onClick={() => {
              copyGeneratedText(false, true);
            }}
            pl={8}
            pr={8}
            className={styles.approveAndCopy}
          >
            Approve and copy
          </Button>
        </Flex>
        <Box mt="0.5rem" />
        {createFeedbackButtons("oe-feedback", !oeResponse?.rowKey || fbOeLoading || fbOeSuccess.success, fbOeSuccess, fbOeLoading, isModalOpen)}
      </Flex>
    );
  };

  const inputForm = createInputForm();
  const outputForm = createOutputForm();
  const socialMedia = createSocialMediaForm();
  const outreachEmail = createOutreachEmailForm();

  return (
    <Flex>
      <Box width={isOpen ? "20%" : "0%"}>
        <SideBar
          history={history}
          loading={hsLoading}
          setHistoryData={setHistoryData}
          setResponse={setResponse}
          setFile={setFile}
          setSs={setSs}
          setScn={setScn}
          setOriginalResponse={setOriginalResponse}
          setAdParameters={setAdParameters}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          refresh={refresh}
          isModalOpen={isModalOpen}
        ></SideBar>
      </Box>
      <Box width={isOpen ? "80%" : "100%"}>
        <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="1rem" pb={2} className={styles.main} key="RWG">
          <GridItem w="97%" className={styles.border} marginLeft="auto" position="relative">
            <Flex direction="column" alignItems="center">
              {inputForm}
            </Flex>
            <Flex direction="row" justifyContent="flex-end" className={styles.writeMyAdd} id="writeMyAd">
              <Button
                size="lg"
                variant="solid"
                backgroundColor={mainColor}
                _hover={{ opacity: "0.93" }}
                color="white"
                type="submit"
                form="inputForm"
                minW="auto"
                isLoading={isSubmitting}
                tabIndex={9}
              >
                {<Icon color="orange" as={BsStars} w={5} h={5} />}&nbsp;Write my ad
              </Button>
            </Flex>
          </GridItem>
          <GridItem w="97%" className={styles.border} marginRight="auto">
            <Flex direction="column" alignItems="center">
              <SimpleTabs
                tab1={outputForm}
                tab2={socialMedia}
                tab3={outreachEmail}
                tab1Loading={loading}
                tab2Loading={smLoading}
                tab3Loading={oeLoading}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setLastActiveTab={setLastActiveTab}
                isDisabled={(loading || !response.textResult) && tabsLocked}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};
