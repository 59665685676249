import { Button, Collapse, Box, Icon, Text, Input } from "@chakra-ui/react";
import { useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { SideBarParameters, HistoryResult } from "../../api/types";
import { BsPencilSquare } from "react-icons/bs";
import Spinner from "./Spinner";

import "./SideBar.css";

const makeCleanDate = (date: string) => {
  const splited = new Date(date).toLocaleString().split(":");
  return `${splited[0].split(",")[0]}, ${splited[0].split(",")[1]}:${splited[1]}`;
};

const SideBar = (props: SideBarParameters) => {
  const [selectedOption, setSelectedHistory] = useState<any>({});
  const [keyword, setKeyword] = useState<string>("");

  const handleClick = (selected: HistoryResult) => {
    props.refresh(false);
    if (selected && selected.rowKey === selectedOption?.rowKey) {
      setSelectedHistory({});
      return;
    }
    setSelectedHistory(selected);
    const data: HistoryResult = selected;
    props.setHistoryData(data);
    props.setAdParameters(data);
    props.setResponse({ jsonResult: data?.json_result, textResult: data?.text_result });
    props.setOriginalResponse({ jsonResult: data?.json_result, textResult: data?.text_result });
    props.setSs(data.show_salary);
    props.setScn(data.show_client_name);
    if (data?.file_data) {
      const file = JSON.parse(data?.file_data);
      props.setFile(file);
    } else {
      props.setFile(undefined);
    }
  };

  const onToggle = () => props.setIsOpen((pv: boolean) => !pv);

  return (
    <>
      {!props.isOpen && (
        <Button
          size="sm"
          onClick={onToggle}
          bg="#335D83"
          color="white"
          _hover={{ opacity: "0.93" }}
          style={{ position: "absolute", zIndex: "10000", top: "44%", left: "-0.3%" }}
          fontSize="15px"
          fontWeight="800"
          textAlign="right"
          padding={0}
        >
          <Icon as={IoIosArrowForward} fontSize="20px" />
        </Button>
      )}
      <Collapse in={props.isOpen} animateOpacity className="border" id="history">
        <Box borderTopRightRadius="7px" borderBottomRightRadius="7px" mt={2} padding={2} height="100%" width="100%" position="relative" pt={0}>
          {!props.isModalOpen && (
            <Button
              size="sm"
              bg="#335D83"
              color="white"
              _hover={{ opacity: "0.93" }}
              onClick={onToggle}
              style={{ position: "absolute", zIndex: "10000", top: "36%", left: "90%" }}
              fontSize="15px"
              fontWeight="800"
              textAlign="left"
              padding={0}
            >
              <Icon as={IoIosArrowBack} fontSize="20px" />
            </Button>
          )}

          <Button
            width="100%"
            marginBottom={7}
            variant="solid"
            backgroundColor="#335D83"
            borderColor="#335D83"
            _hover={{ opacity: "0.93" }}
            color="white"
            onClick={() => {
              props.refresh();
              setSelectedHistory({});
            }}
          >
            <span style={{ marginLeft: "8px" }}>Create new ad</span> <Icon as={BsPencilSquare} marginLeft="auto" marginRight={2} />
          </Button>

          <Text fontSize="1.4em" fontWeight="500" mb={props.loading ? 8 : 4} textAlign="center" color="black">
            History of ads
          </Text>
          {props.history?.length ? <Input placeholder="Search..." width="100%" mb={6} onChange={e => setKeyword(e.target.value)} value={keyword} /> : null}

          {props.loading && <Spinner />}
          <ul>
            {props.history && props.history.length
              ? props.history
                  .filter(
                    (h: HistoryResult) =>
                      !keyword ||
                      h?.title?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                      makeCleanDate(h?.timestamp || "")?.includes(keyword.toLocaleLowerCase())
                  )
                  .sort((a: any, b: any) => +new Date(b.timestamp) - +new Date(a.timestamp))
                  .map((x: HistoryResult, i: number) => (
                    <Button
                      key={i}
                      onClick={() => handleClick(x)}
                      fontSize="11px"
                      mb={2.5}
                      className={`item ${selectedOption ? (x.rowKey === selectedOption?.rowKey ? "active" : "") : ""}`}
                    >{`${x?.title} (${makeCleanDate(x?.timestamp || "")})`}</Button>
                  ))
              : null}
          </ul>
          {!props.loading && !props.history?.length ? <Text textAlign="center">No history.</Text> : null}
        </Box>
      </Collapse>
    </>
  );
};

export default SideBar;
