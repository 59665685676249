import { AiFillFileAdd } from "react-icons/ai";
import { Flex, Text, Input, Icon, Button } from "@chakra-ui/react";
import { useRef, useState } from "react";

interface FileUploaderProps {
  onFileUpload: (file: File, cv: boolean) => Promise<void>;
  isCv?: boolean;
  isDisabled?: boolean;
}

export const FileUploader = ({ onFileUpload, isCv, isDisabled }: FileUploaderProps) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string>("");

  const onZoneClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
      inputFileRef.current.click();
    }
  };

  const handleFile = async (f: File) => {
    if (
      f.type === "application/pdf" ||
      f.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      await onFileUpload(f, !!isCv);
    } else {
      if(f.type === 'application/msword'){
        setError(`You’ve uploaded an out-of-date version of a Word document (.DOC). Please save this file again in Word as a .DOCX  or PDF file and upload it again.`)
      }else{
        setError("File type not supported - only PDF and DOCX are supported");
      }
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const f: File | undefined = e.target.files?.[0];

    if (f) {
      await handleFile(f);
    }
  };

  const dropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    hole(e);
    const f: File | undefined = e.dataTransfer.files?.[0];

    if (f) {
      await handleFile(f);
    }
  };

  const hole = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Wrapper isCv={isCv} dropHandler={dropHandler} hole={hole} onZoneClick={onZoneClick}>
      {!isCv ? (
        <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
          <Icon as={AiFillFileAdd} w={10} h={10} color="#335D83" />
          <Flex direction="column" ml={2}>
            <Text color="gray.400">
              Drag & drop job description or{" "}
              {
                <a href="#" onClick={e => e.preventDefault()} style={{ color: "#335D83" }}>
                  upload
                </a>
              }
            </Text>
            <Text color="gray.400" fontSize="small">
              File Type <b style={{ color:"#335D83" }}>PDF / DOCX</b> &nbsp;|&nbsp; File Size: 25MB Max
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Button isDisabled={isDisabled} variant="solid" backgroundColor="#335D83" _hover={{ opacity: "0.93" }} color="white" width="100%" pl={8} pr={8}>
          Upload CV
        </Button>
      )}
      <Text color="red.400" ml="0.85rem" fontSize="12.5" textAlign="center" fontWeight={600}>
        {error}
      </Text>
      <Input type="file" accept=".pdf, .docx" ref={inputFileRef} onChange={handleFileChange} style={{ display: "none" }} />
    </Wrapper>
  );
};

const Wrapper = ({ children, isCv, dropHandler, hole, onZoneClick }: any) => {
  if (isCv) {
    return (
      <Flex width="100%" onDrop={dropHandler} onDragEnter={hole} onDragOver={hole} onClick={onZoneClick} tabIndex={7}>
        {children}
      </Flex>
    );
  }
  return (
    <Flex
      direction="column"
      width="90%"
      margin="0 auto"
      height="6rem"
      paddingRight={2}
      alignItems="center"
      justifyContent="center"
      onDrop={dropHandler}
      onDragEnter={hole}
      onDragOver={hole}
      onClick={onZoneClick}
      tabIndex={7}
      border="2px dashed gray"
      borderColor="#335D83"
      borderRadius={5}
    >
      {children}
    </Flex>
  );
};
